<template>
    <div class="login-content">
        <div class="main-content">
            <div class="left-box">
                <img src="../assets/images/evaluation_login.png" alt="">
            </div>
            <div class="right-box">
                <div class="tip-title">欢迎登录！</div>
                <div class="center-box">
                    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="demo-ruleForm" label-width="110px"
                             label-position="left">
                        <el-form-item prop="username" label="账号">
                            <el-input v-model="ruleForm.username" @keyup.enter.native="loginBtn('ruleForm')"
                                      placeholder="请输入账号">
                                <i slot="prefix" class="iconfont">&#xea3d;</i>
                            </el-input>
                        </el-form-item>
                        <el-form-item prop="password" label="密码">
                            <el-input v-model="ruleForm.password" @keyup.enter.native="loginBtn('ruleForm')"
                                      type="password" placeholder="请输入密码">
                                <i slot="prefix" class="iconfont">&#xe82e;</i>
                            </el-input>

                        </el-form-item>
                    </el-form>
                    <el-button size="medium" class="teacher-btn" @click="loginBtn('ruleForm')">登录</el-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {userLogin, examStuExamPage} from '@/utils/apis'
    export default {
        data() {
            return {
                ruleForm: {
                    username: '',
                    password: '',
                },
                rules: {
                    username: [
                        { required: true, message: '请输入账号', trigger: 'blur' },
                        { min: 3, max: 90, message: '长度在 3 到 90 个字符', trigger: 'blur' }
                    ],
                    password: [
                        { required: true, message: '请输入密码', trigger: 'blur' },
                    ],
                }
            }
        },
        methods: {
            loginBtn(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        let formData = new FormData()
                        formData.append('username', this.ruleForm.username)
                        formData.append('password', this.ruleForm.password)
                        new Promise((resolve, reject) => {
                          userLogin(formData).then((res) => {
                            localStorage.setItem('role', res.data.role)
                            if (res.data.role === 1) {
                              localStorage.setItem('adminToken', res.data.token);
                              localStorage.setItem('adminName', res.data.nickname);
                              localStorage.setItem('adminAvatar', res.data.avatar);
                              localStorage.setItem('hosturl', res.data.hosturl);
                              this.$message.success({
                                type: 'success',
                                message: res.msg,
                                duration: 1000
                              })
                              // this.$router.push('/educational/school')
                              this.$router.push('/identifyCenter/index')
                            } else if (res.data.role === 2) {
                              localStorage.setItem('schoolToken', res.data.token);
                              localStorage.setItem('schoolName', res.data.nickname);
                              localStorage.setItem('schoolAvatar', res.data.avatar);
                              localStorage.setItem('hosturl', res.data.hosturl);
                              this.$message.success({
                                type: 'success',
                                message: res.msg,
                                duration: 1000
                              })
                              this.$router.push('/school/educational/teacher')
                            } else if (res.data.role === 3) {
                              localStorage.setItem('teacherToken', res.data.token);
                              localStorage.setItem('teacherName', res.data.nickname);
                              localStorage.setItem('teacherAvatar', res.data.avatar);
                              localStorage.setItem('hosturl', res.data.hosturl);
                              this.$message.success({
                                type: 'success',
                                message: res.msg,
                                duration: 1000
                              })
                              this.$router.push('/teachingCenter/index')
                            } else if (res.data.role === 4) {
                              localStorage.setItem('judgesToken', res.data.token);
                              localStorage.setItem('judgesName', res.data.nickname);
                              localStorage.setItem('judgesAvatar', res.data.avatar);
                              localStorage.setItem('hosturl', res.data.hosturl);
                              localStorage.setItem('checkerName', res.data.check_center_name);
                              this.$message.success({
                                type: 'success',
                                message: res.msg,
                                duration: 1000
                              })
                              this.$router.push('/judges/grading/index')
                            } else if (res.data.role === 5) {
                              localStorage.setItem('studentId', res.data.id);
                              localStorage.setItem('studentToken', res.data.token);
                              localStorage.setItem('studentName', res.data.nickname);
                              localStorage.setItem('studentAvatar', res.data.avatar);
                              localStorage.setItem('hosturl', res.data.hosturl);
                              this.$message.success({
                                type: 'success',
                                message: res.msg,
                                duration: 1000
                              })
                              if (Number(res.data.exam_form) === 1) { //实操考试：exam_form: 1
                                localStorage.setItem('examId', res.data.exam_id);
                                localStorage.setItem('exam_form', 1)
                                resolve(true)
                              }else if(Number(res.data.exam_form) === 0){ //理论考试：exam_form: 0
                                // this.$router.push('/authen/authenticate')
                                /*this.$router.push({
                                  path: "/authen/authenticate",
                                  query: { id: res.data.examination_id },
                                });*/
                                localStorage.setItem('exam_form', 0)
                                resolve({type: 0, data: res.data})
                              } else {
                                this.$router.push('/student/course/index')
                              }
                            }else if(res.data.role===6){
                              localStorage.setItem('evaluationToken', res.data.token);
                              localStorage.setItem('evaluationName', res.data.nickname);
                              localStorage.setItem('evaluationAvatar', res.data.avatar);
                              localStorage.setItem('hosturl', res.data.hosturl);
                              localStorage.setItem('check_center_id', res.data.check_center_id);
                              localStorage.setItem('check_center_name', res.data.check_center_name);
                              this.$message.success({
                                type: 'success',
                                message: res.msg,
                                duration: 1000
                              })
                              this.$router.push('/evaluation')
                            }
                          }).catch((err) => {})

                        }).then(res => {
                            console.log("res", res)
                            if (res.type === 0) {
                              this.$router.push({
                                path: '/student/evaluation/examing/theoryTest/index',
                                query: { id: res.data.examination_id }
                              })
                              return
                            }

                            examStuExamPage().then(res => {
                                if (res.data.begin_time && res.data.begin_time > (Date.parse(new Date()) / 1000)) {
                                    this.$router.push('/student/wait')
                                    return;
                                }
                                localStorage.setItem('exam_type', parseInt(res.data.type));
                                localStorage.setItem('end_time', res.data.end_time);
                              // 四级
                                if (res.data.exam_grade === 1) {
                                  if (res.data.goods_info_collect_name) {
                                    this.$router.push('/student/examing/goodsInfo/index')
                                    return
                                  }
                                  if (res.data.shop_decoration_name) {
                                    this.$router.push('/student/examing/onlineShopDecoration/index')
                                    return;
                                  }
                                  if (res.data.shop_management_name) {
                                    this.$router.push('/student/examing/onlineShopManagement/index');
                                    return;
                                  }
                                  if (res.data.network_promotion_name) {
                                    this.$router.push('/student/examing/webPromotion/index');
                                    return;
                                  }
                                  if (res.data.store_customer_name) {
                                    this.$router.push('/student/examing/onlineShopCustomerService/index');
                                    return;
                                  }
                                  if (res.data.data_capture_clean_name) {
                                    this.$router.push('/student/examing/ECommerceDataCollectionAndCleaning/index');
                                    return;
                                  }
                                  this.$message.warning("没有任何考试模块，请再次确认考试试卷是否正确")
                                } else if (res.data.exam_grade === 24) { // 三级
                                  if (res.data.product_service_management_name) {
                                    return this.$router.push('/student/exam/productSerive/index');
                                  }
                                  if (res.data.store_visual_design_name) {
                                    return this.$router.push('/student/exam/onlineShopVisualDesign/index');
                                  }
                                  if (res.data.network_marketing_name) {
                                    return this.$router.push('/student/exam/internetMarketing/index');
                                  }
                                  if (res.data.online_transaction_management_name) {
                                    return this.$router.push('/student/exam/onlineTradeManage/index');
                                  }
                                  if (res.data.store_customer_service_level_three_name) {
                                    return this.$router.push('/student/exam/internetCustomerService/index');
                                  }
                                  if (res.data.data_analysis_application_name) {
                                    return this.$router.push('/student/exam/ECommerceDataAnalysisAndApplication/index');
                                  }                               
                                } else if (res.data.exam_grade === 51) {  // 四级跨境
                                  if (res.data.goods_info_collect_name) {
                                    return this.$router.push('/student/examing/goodsInfo/index');
                                  }
                                  if (res.data.store_visual_design_name) {
                                    return this.$router.push('/student/exam/onlineShopVisualDesign/index');
                                  }
                                  if (res.data.network_promotion_name) {
                                    return this.$router.push('/student/examing/webPromotion/index');
                                  }
                                  if (res.data.shop_management_name) {
                                    return this.$router.push('/student/examing/onlineShopManagement/index');
                                  }
                                  if (res.data.store_customer_service_name) {
                                    return this.$router.push('/student/exam/internetCustomerService/index');
                                  }
                                  if (res.data.data_capture_clean_name) {
                                    return this.$router.push('/student/examing/ECommerceDataCollectionAndCleaning/index');
                                  }
                                }else if(res.data.exam_grade === 74){
                                  //三级跨境
                                  if (res.data.product_service_management_name) {
                                    return this.$router.push('/student/exam/productSerive/index');
                                  }
                                  if (res.data.store_visual_design_name) {
                                    return this.$router.push('/student/exam/onlineShopVisualDesign/index');
                                  }
                                  if (res.data.network_marketing_name) {
                                    return this.$router.push('/student/exam/internetMarketing/index');
                                  }
                                  if (res.data.shop_management_name) {
                                    this.$router.push('/student/examing/onlineShopManagement/index');
                                    return;
                                  }

                                  if (res.data.store_customer_name) {
                                    this.$router.push('/student/examing/onlineShopCustomerService/index');
                                    return;
                                  }

                                  if (res.data.data_analysis_application_name) {
                                    return this.$router.push('/student/exam/ECommerceDataAnalysisAndApplication/index');
                                  }
                                  
                                }else if (res.data.exam_grade === 74){//三级跨境
                                  // if (res.data.shop_management_name) {
                                  //   this.$router.push('/student/examing/onlineShopManagement/index');
                                  //   return;
                                  // }
                                  // if (res.data.store_visual_design_name) {
                                  //   this.$router.push('/student/exam/onlineShopVisualDesign/index');
                                  // }
                                  // if (res.data.network_marketing_name) {
                                  //   this.$router.push('/student/exam/internetMarketing/index');
                                  // }
                                  // if (res.data.online_transaction_management_name) {
                                  //   this.$router.push('/student/exam/onlineTradeManage/index');
                                  // }
                                  // if (res.data.store_customer_service_level_three_name) {
                                  //   this.$router.push('/student/exam/internetCustomerService/index');
                                  // }
                                  // if (res.data.data_analysis_application_name) {
                                  //   this.$router.push('/student/exam/ECommerceDataAnalysisAndApplication/index');
                                  // }
                                  // if (res.data.product_service_management_name) {
                                  //   this.$router.push('/student/exam/productSerive/index');
                                  // }
                                }
                            })
                        })

                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
        }
    }
</script>

<style scoped lang="scss">
    .login-content {
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-image: url("../assets/images/evaluation_back.png");
        background-repeat: no-repeat;
        background-size: cover;

        .main-content {
            height: 100%;
            width: 100%;
            margin: 0 160px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .left-box {
                margin-top: 90px;
                height: calc(100% - 90px);

                img {
                    max-height: 100%;
                }
            }
        }

        .right-box {
            width: 500px;
            height: 580px;
            background: #FFFFFF;
            box-shadow: 0px 0px 18px 3px rgba(18, 30, 87, 0.1);
            border-radius: 8px;
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;

            .tip-title {
                position: absolute;
                left: 60px;
                top: 100px;
                font-size: 24px;
                color: #060211;
            }

            .center-box {
                margin: 200px 60px 0;
                width: calc(100% - 120px);
            }

            .el-button {
                width: 100%;
                background: #2DC079;
                color: #fff;
                font-size: 16px;
                height: 40px;
                border-radius: 4px;
                margin-top: 66px
            }

            ::v-deep .el-form {
                .el-form-item {
                    margin-bottom: 5px;
                }

                .el-form-item__label:before {
                    content: '';
                }

                .el-form-item__content {
                    margin-left: 0 !important;
                    width: 100%;
                }
            }
        }
    }
</style>
